import React, { useEffect } from "react";
import HowSection1 from "../../components/HowWorksSections/HowSection1/HowSection1";
import "./howWorks.css";
import HowSection2 from "../../components/HowWorksSections/HowSection2/HowSection2";
import HowSection3 from "../../components/HowWorksSections/HowSection3/HowSection3";
import Footer from "../../components/Footer/Footer";
const HowWorks = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);
  return (
    <div className="HowWorks">
      <HowSection1 />
      <HowSection2 />
      <HowSection3 />
      <Footer />
    </div>
  );
};

export default HowWorks;
