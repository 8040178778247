import React from "react";
import "./card.css";

const Card = ({ title, description, image }) => {
  return (
    <div className="card">
      <div>
        <img className="cardImg" src={image} alt="Card" />
      </div>
      <div className="cardDetails">
        <h3 className="cardTitle">{title}</h3>
        <p className="cardDesc">{description}</p>
      </div>
    </div>
  );
};

export default Card;
