import React, { useEffect } from "react";
import "../blog.css";
import BlogSection2 from "../../../components/BlogSections/BlogSection2/BlogSection2";
import BlogSection1 from "../../../components/BlogSections/BlogSection1/BlogSection1";
import Footer from "../../../components/Footer/Footer";
import Navbar from "../../../components/Navbar/Navbar";
const Blog2 = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);
  return (
    <>
      <Navbar />
      <div className="Blog">
        <BlogSection1 />
        <BlogSection2
          title={"Women Of Steel- Celebrating The Women In Construction"}
          desc={
            "What is the first thing that comes to your mind when you think of a general contractor?" +
            "+Construction industry is always seen as a male dominant industry. It isn’t hard to believe that as of 2019, women only make up to 10.1% of the construction industry out of which only 3.5% work in the fields and trades. Although the workplaces & teams are cooperative, there has been an unconscious bias across the industry, making the construction industry look like a male dominant industry. Even after such changes there is still a gap that we need to fill. There is still a lot of work that has to be done in order to bring true equality in the construction industry." +
            "+We need to take baby steps in order to achieve true equality and initiative like women in construction week. Earlier it was not easy to sustain a minority in such an environment, but the conditions have changed over the period of time, where women are given opportunity in the industry.With the ever updating technology and internet literacy it becomes easy to work on and off the field. Women are now considered equally competent for the job, they have mutual skills and as they’re known for their power of negotiation, they strive to bring success wherever they contribute. Experts say they bring balance to the job site."+
"Even the workplaces are becoming more responsive and adapting in terms of their working & basic requirements of working women. Few construction companies are led by women employees, giving it a chance to grow. There are a lot of women who work in the field reaching out to the standards of men. In fact according to most women they love working in this industry as there is something new to do everyday and every project offers a different challenge in front of them. And with more women entering the field they are breaking the glass ceiling."+
"With the attempt by associations like NAWIC trying to make it a welcoming industry for women which is revolutionary for the industry . With their aim to pave new roads to construction, there has been a positive shift in the climate within the construction industry. And what a better way to cherish these achievements than to celebrate this week followed by International women’s day. Women working in industry so rough and sturdy, itself a sign of evolving mindsets, diminishing prejudiced conventions & most importantly a symbol of hope & encouragement, for the coming generations."
          }
        />
        <Footer />
      </div>
    </>
  );
};

export default Blog2;
