import React from 'react'
import OwnerSection1 from '../../components/OwnerSection/OwnerSection1/OwnerSection1'
import OwnerSection2 from '../../components/OwnerSection/OwnerSection2/OwnerSection2'
import Footer from '../../components/Footer/Footer'
import "./owner.css"
const Owner = () => {
  return (
    <div className='Owner'>
    <OwnerSection1 />
    <OwnerSection2 />
    <Footer />
    </div>
  )
}

export default Owner