import React, { useEffect } from "react";
import BlogSection1 from "../../components/BlogSections/BlogSection1/BlogSection1";
import "./blog.css";
import BlogSection2 from "../../components/BlogSections/BlogSection2/BlogSection2";
import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/Navbar/Navbar";
const Blog = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);
  return (
    <>
      <Navbar />
      <div className="Blog">
        <BlogSection1 />
        <BlogSection2
          title={"Virtual Construction Management Platform"}
          desc={
            "SiteView tools will be integrated in a virtual, BIM-enabled, cloud-based construction management platform which is structured to mirror the most advanced integrated design and delivery frameworks for the building sector." +
            "+The platform will provide installation guidelines, shared design specifications, 3D models and relevant information on regulatory frameworks." +
            "+In addition, workers and their supervisors will have access to workflow, status, training information and product data sheets through their tablets. During the project, this platform will be integrated into the operations of small and medium-sized enterprise (SME) contractors, large construction firms and end user clients directly within the consortium and work program activities, assuring systematic and scientific performance measures, feedback and powerful exploitation."
          }
        />
        <Footer />
      </div>
    </>
  );
};

export default Blog;
