import React from "react";
import "./footer.css";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { Link } from "react-router-dom";
const Footer = () => {
  const redirectToLinkedIn = () => {
    window.open("https://www.linkedin.com/company/siteviewai/about/", "_blank");
};
const redirectToFacebook = () => {
  window.open("https://www.facebook.com/profile.php?id=100095546374275", "_blank");
};
  const redirectToYoutube = () => {
    window.open("https://www.youtube.com/channel/UCCtOohV3-rtEacRQgvuFOpQ", "_blank");
};
  return (
    <div className="Footer">
      <div>
        <div className="aboutFooterSection">
          <h3>NEXT GEN CONSTRUCTION MANAGEMENT</h3>

          <div>
            <p>
              “If the whole construction project can be shown digitally, and
              available at your fingertips.. then the possibilities are immense”
            </p>
          </div>
        </div>
        <div className="categoryFooterSection">
          <h3>SiteView</h3>

          <div >
            <Link style={{ textDecoration: "none", color: "black"  }} to="/">
              <p style={{marginTop:"10px"}}>Home</p>
            </Link>
            <Link
              style={{ textDecoration: "none", color: "black" }}
              to="/howWorks"
            >
              <p>Learn More</p>
            </Link>
            <Link style={{ textDecoration: "none", color: "black" }} to="/aboutUs">
              <p>About Us</p>
            </Link>{" "}
            <Link
              style={{ textDecoration: "none", color: "black" }}
              to="/owner"
            >
              <p>For Owner</p>
            </Link>{" "}
            <Link
              style={{ textDecoration: "none", color: "black" }}
              to="/consultant"
            >
              <p>For Consultants & Designers</p>
            </Link>{" "}
            <Link
              style={{ textDecoration: "none", color: "black" }}
              to="/contractors"
            >
              <p>For Contractors</p>
            </Link>
          </div>
        </div>
        <div className="contactFooterSection">
          <h3>Contact Us</h3>
          <div className="empty"></div>
          <div style={{ marginTop: "30px", fontSize: "1.2rem" }}>
            <Link
              style={{ textDecoration: "none", color: "black" }}
              to="/contact"
            >
              <p>Get In Touch</p>
            </Link>
          </div>
          <div className="iconDiv">
            <div onClick={redirectToFacebook}>
              <FacebookOutlinedIcon style={{ fontSize: "2rem" }} />
            </div>
            <div onClick={redirectToLinkedIn}>
              <LinkedInIcon style={{ fontSize: "2rem" }} />
            </div>
            <div >
              <InstagramIcon style={{ fontSize: "2rem" }} />
            </div>
            <div onClick={redirectToYoutube}>
              <YouTubeIcon style={{ fontSize: "2rem" }} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
