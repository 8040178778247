import React from 'react'
import img1 from "../../../assets/image/blog-home.png";
import "./blogSection1.css"
const BlogSection1 = () => {
  return (
    <div>
      <div className="BlogDiv" data-aos="fade-up" data-aos-duration="1000">
          <img src={img1} alt="Contact" className="blogImage" />
          <h1 className="blogText">SITEVIEW BLOG</h1>
        </div>
    </div>
  )
}

export default BlogSection1