import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import "./home.css";
import Footer from "../../components/Footer/Footer";
import Section6 from "../../components/HomeSections/Section6/Section6";
import HomeImage from "../../components/HomeSections/HomeImage/HomeImage";
import Section2 from "../../components/HomeSections/Section_2/Section2";
import Section3 from "../../components/HomeSections/Section3/Section3";
import Section4 from "../../components/HomeSections/Section4/Section4";
import Section5 from "../../components/HomeSections/Section5/Section5";
import Section7 from "../../components/HomeSections/Section7/Section7";
import arrowdown from "../../assets/NewImages/down-arrow-svgrepo-com.png"
import icondown from "../../assets/NewImages/Next to Scroll icon on the side.png"


const Home = () => {
  return (
    <div className="main_home">
      <Navbar />
      <div >
        <img className="arrowDown2" src={icondown} alt="arrow"></img>
        <img className="arrowDown" src={arrowdown} alt="arrow"></img>
      </div>
      <HomeImage />
      <Section2 />
      <Section3 />
      <Section4 />
      <Section5 />
      <Section6 />
      <Section7 />
      <Footer />
    </div>
  );
};

export default Home;
