import React, { useEffect } from "react";
import Navbar from "../../components/Navbar/Navbar";
import aboutImg from "../../assets/NewImages/About Us.jpg";
import "./aboutUs.css";
import Footer from "../../components/Footer/Footer";
const AboutUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);
  return (
    <>
      <div style={{ overflowX: "hidden" }}>
        <Navbar />

        <div className="AboutUs">
          <img src={aboutImg} alt="About Us" />
        </div>
       
        <Footer />
      </div>
    </>
  );
};

export default AboutUs;
