import React, { useState } from "react";
import "./section2.css";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { useNavigate } from "react-router-dom";
const Section2 = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleLearnMore = () => {
    navigate("/howworks");
  };
  return (
    <>
      <div className="Desc_1">
        <div>
          <h1>
            SiteView is a 360 degree Photo Documentation and Virtual Management
            product which scans your entire project site, so you can monitor the
            projects remotely and also store important visual data that helps
            ease Construction Management processes.
          </h1>
        </div>
        <div className="DescBtn">
          <button className="DescBtn1" onClick={handleLearnMore}>
            Learn More
          </button>
          <button className="DescBtn_2" onClick={handleClickOpen}>
            Watch Video
          </button>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
            <div className="videoWrapper">
                  <iframe
                    className="youtubeVideo"
                    src="https://www.youtube.com/embed/eVKXEJMt1Vs"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                  ></iframe>
                </div>
            </DialogContent>
          </Dialog>
        </div>
      </div>
    </>
  );
};

export default Section2;
