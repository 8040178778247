import React from "react";
import "./blogSection2.css";

const BlogSection2 = ({ title, desc }) => {
  // Split the desc string by '+' to separate paragraphs
  const paragraphs = desc.split("+");

  return (
    <div className="BlogSection2">
      <h1 className="BlogSection2Heading">{title}</h1>
      <div className="blogDescription">
        {paragraphs.map((paragraph, index) => (
          <p key={index}>{paragraph.trim()}</p>
        ))}
      </div>
    </div>
  );
};

export default BlogSection2;
