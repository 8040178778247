import React, { useEffect } from "react";
import "./section3.css";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useAnimation } from "framer-motion";
import { Link } from "react-router-dom";

const Section3 = () => {
  const { ref, inView } = useInView();
  const animation = useAnimation();

  useEffect(() => {
    if (inView) {
      animation.start({
        scale: 1.5,
        transition: {
          type: "easeInOut",
          duration: 1,
        },
      });
    }
    if (!inView) {
      animation.start({
        scale: 1,
        transition: {
          type: "easeOutIn",
          duration: 1,
        },
      });
    }
  }, [inView, animation]);

  const { ref: card1Ref, inView: card1InView } = useInView();
  const card1Animation = useAnimation();

  useEffect(() => {
    const isMobile = window.innerWidth <= 768; // Adjust the breakpoint as needed

    if (!isMobile) {
      if (card1InView) {
        card1Animation.start({
          y: -50,
          transition: {
            type: "easeInOut",
            duration: 1,
          },
        });
      } else {
        card1Animation.start({
          y: 50,
          transition: {
            type: "easeOutIn",
            duration: 1,
          },
        });
      }
    }
  }, [card1InView, card1Animation]);

  const { ref: card2Ref, inView: card2InView } = useInView();
  const card2Animation = useAnimation();

  useEffect(() => {
    const isMobile = window.innerWidth <= 768; // Adjust the breakpoint as needed

    if (!isMobile) {
      if (card2InView) {
        card2Animation.start({
          y: -150,
          transition: {
            type: "easeInOut",
            duration: 1,
          },
        });
      } else {
        card2Animation.start({
          y: 150,
          transition: {
            type: "easeOutIn",
            duration: 1,
          },
        });
      }
    }
  }, [card2InView, card2Animation]);
  return (
    <>
      <div className="Section_3">
        <div ref={ref} className="section3Heading">
          <motion.h1 animate={animation}>BENEFITS</motion.h1>
        </div>
        <div className="section3Cards">
          <Link to="/owner">
            <motion.div
              ref={card1Ref}
              animate={card1Animation}
              className="card1"
            ></motion.div>
          </Link>
          <Link to="/consultant">
            <motion.div
              ref={card2Ref}
              animate={card2Animation}
              className="card2"
            ></motion.div>
          </Link>
          <Link to="/contractors">
            <motion.div
              ref={card1Ref}
              animate={card1Animation}
              className="card3"
            ></motion.div>
          </Link>
        </div>
      </div>
    </>
  );
};

export default Section3;
