import React from "react";
import "./section6.css";
import Card from "../../Card/Card";
import { motion } from "framer-motion"; // Import motion from framer-motion
import img1 from "../../../assets/NewImages/HOME_PAGE/save_time.jpeg";
import img3 from "../../../assets/NewImages/HOME_PAGE/digital_record.jpeg";
import img2 from "../../../assets/NewImages/HOME_PAGE/save_cost.jpeg";
import img4 from "../../../assets/NewImages/HOME_PAGE/virtual_site.jpeg";
import { useNavigate } from "react-router-dom";

const Section6 = () => {
  const navigate = useNavigate();
  const handleLearnMore = () => {
    
    navigate("/howworks");
  };
  const cardHover = {
    y: -50,
    transition: {
      duration: 0.3,
    },
  };

  return (
    <div className="Section6">
      <div className="section6Heading">
        <h1>
          Site View costs only 0.1% of your project cost, but helps save millions.
        </h1>
      </div>
      <div className="section6Card">
        {[1, 2, 3, 4].map((index) => (
          <motion.div
            key={index}
            whileHover={cardHover}
            style={{
              marginTop: index % 2 === 1 ? "100px" : "0",
              transform: index % 2 === 1 ? "translateY(-100px)" : "none",
            }}
          >
            <Card
            // title={index === 1 ? "SAVE TIME" : index === 2 ? "SAVE COST" : index === 3 ? "100% DATA & REPORTS" : "EASY COLLABORATION"}
              description={
                index === 1
                  ? "Save time by ‘walking’ your project from your mobile or computer screen at you convenience, from anywhere"
                  : index === 2
                  ? "Save on unnecessary hiring of staff for reporting and monitoring, avoid disputes, reworks  and faulty workmanship before they happen."
                  : index === 3
                  ? "Visuals are the first source of any data and reports, and we provide that instantly. No more relying on limited view of 2Dphotos  when you can go 360 !"
                  : "Collaborate with your engineers, architects and contractors from wherever they are. Mark instructions on photos and share the whole project digitally during online meetings."
              }
              image={index === 1 ? img1 : index === 2 ? img2 : index === 3 ? img3 : img4}
            />
          </motion.div>
        ))}
      </div>
      <div className="sectionbtndiv">
        <button className="section6button" onClick={handleLearnMore}>Learn More</button>
      </div>
    </div>
  );
};

export default Section6;
