import React from 'react'
import ConsultantSection1 from '../../components/ConsultantsSections/ConsultantsSection1/ConsultantSection1'
import ConsultantSection2 from '../../components/ConsultantsSections/ConsultantsSection2/ConsultantsSection2'
import Footer from '../../components/Footer/Footer'

const Consultant = () => {
  return (
    <div style={{overflowX:"hidden"}}>
    <ConsultantSection1 />
    <ConsultantSection2 />
    <Footer />
    </div>
  )
}

export default Consultant