import React, { useEffect } from "react";
import "./contacts.css";
import Navbar from "../../components/Navbar/Navbar";
import { Button } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import TelegramIcon from "@mui/icons-material/Telegram";
import PublicIcon from "@mui/icons-material/Public";
import Footer from "../../components/Footer/Footer";

const Contacts = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);
  return (
    <>
      <Navbar />
      <div className="contact">
        <div className="contactDiv1">
          <h1 className="contactText">Get In Touch</h1>
        </div>

        <div className="contactDiv3">
          <div className="contactForm">
            <div>
              <h2>Contact Us</h2>
              {/* ---> form submission using formspree*/}
              <form action="https://formspree.io/f/xoqoeqwo" method="POST"> 
                <div className="emailDiv">
                  <div>
                    <p>Full Name</p>
                    <input
                      placeholder="Name"
                      type="text"
                      id="name"
                      name="name"
                      required
                    />
                  </div>
                  <div>
                    <p>Email</p>
                    <input
                      placeholder="Email"
                      type="email"
                      id="email"
                      name="email"
                      required
                    />
                  </div>
                </div>
                <div className="NumMsgDiv">
                  <p>Number</p>
                  <input
                    placeholder="Phone Number"
                    type="tel"
                    id="number"
                    name="number"
                  />
                </div>
                <div className="NumMsgDiv">
                  <p>Message</p>
                  <textarea
                    placeholder="Message"
                    id="message"
                    name="message"
                    required
                  ></textarea>
                </div>
                <div>
                  <Button type="submit" variant="contained">
                    Send Message
                  </Button>
                </div>
              </form>
            </div>
            <div></div>
            <div></div>
          </div>
  
        </div>
        <div className="infoDiv">
          <div className="iconWithInfo">
            <LocationOnIcon />
            <p>
              Address:{" "}
              <span style={{ color: "black" }}>Mumbai, India, 400004</span>
            </p>
          </div>
          <div className="iconWithInfo">
            <LocalPhoneIcon />
            <p>
              Phone: <span style={{ color: "black" }}>+91-9819242828</span>{" "}
            </p>
          </div>
          <div className="iconWithInfo">
            <TelegramIcon />
            <p>
              Email: <span style={{ color: "black" }}>yash@siteview.ai</span>{" "}
            </p>
          </div>
          <div className="iconWithInfo">
            <PublicIcon />
            <p>
              Website: <span style={{ color: "black" }}>siteview.ai</span>{" "}
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Contacts;
