import React from "react";
import "./contractorsSection2.css";
import img1 from "../../../assets/NewImages/Benefits/For contractors/SAVE TIME.jpg";
import img2 from "../../../assets/NewImages/Benefits/For contractors/SAVE COST.jpg";
import img3 from "../../../assets/NewImages/Benefits/For contractors/iMPROVE QA QC.jpg";
import img4 from "../../../assets/NewImages/Benefits/For contractors/VERIFY WORK.jpg";
import img5 from "../../../assets/NewImages/Benefits/For contractors/OUTDO COMPETITION-N.jpg";
import img6 from "../../../assets/NewImages/Benefits/For contractors/TIMELY PAYMENTS.jpg";
import img7 from "../../../assets/NewImages/Benefits/For contractors/CONCEALED WORK.jpg";
import img8 from "../../../assets/image/virtual sales.jpg";
import img9 from "../../../assets/NewImages/Benefits/For contractors/AS BUIL DATA.jpg";
import img10 from "../../../assets/NewImages/Benefits/For contractors/SAFETY MANAGEMENT.jpg";
import img11 from "../../../assets/NewImages/Benefits/For contractors/QUICK DOCUMENTATION.jpg";
import Card2 from "../../OwnerSection/Card2/Card2";
const ContractorsSection2 = () => {
  return (
    <div className="ContractorsSection2">
      <Card2
      
        img={img1}
        title={"SAVE TIME"}
        desc={
          "Allow your stakeholders to see the site for themselves with secure, role-based remote site access and handover! Help everyone understand the current jobsite condition and what work has been completed without being there."
        }
      />
      <Card2
      
        img={img2}
        title={"REDUCE COST"}
        desc={
          "From the visuals, Engineers into planning and control as well as senior Engineers can manage multiple projects from the office."
        }
      />
      <Card2
      
        img={img3}
        title={"Improve QA/QC"}
        desc={
          "Quickly communicate specific issues to resolve change orders from the project team or RFIs from the owners. When many eyes monitor simultaneously, quality is well under control"
        }
      />
      <Card2
      
        img={img4}
        title={"VERIFY WORKS"}
        desc={
          "Document as-built conditions as fast as you can walk and easily answer questions like “Where are we compared to the plan?” Make progress reports, validation of work, planning of activities all remotely ."
        }
      />
      <Card2
      
        img={img5}
        title={"OUTDO COMPETITION"}
        desc={
          "Providing work records, as built drawings and handover packages that really provide value to the owner in the long term differentiates your services from the crowd."
        }
      />
      <Card2
      
        img={img6}
        title={"TIMELY PAYMENT"}
        desc={
          "There is always around a months’ time taken to verify the work, prepare measurements and getting paid for what work was done and when it was completed. SiteView eliminates the delay ambiguity. Share curated, time-stamped imagery of your teams’ work and keep the cash flowing."
        }
      />
      <Card2
      
        img={img7}
        title={"CONCEALED WORK"}
        desc={
          "SiteView makes it incredibly easy to document your site completely, allowing you to do in-wall inspections from anywhere, and verify work without time-consuming and expensive destructive verification."
        }
      />
      <Card2
      
        img={img8}
        title={"AS-BUILT DATA"}
        desc={
          "As built data provide precise details about the changes performed at any interim stage of the project. It facilitates easy visualization of the upcoming steps, notice complications, and early solving of issues."
        }
      />
      <Card2
      
        img={img9}
        title={"STAFF PRODUCTIVITY"}
        desc={
          "It’s hard to calculate productivity without clear records of what’s getting done, and how quickly. Oftentimes we see productivity problems too late. SiteView allows you to easily see what is happening on site so you can measure your crews’ productivity and better plan the job."
        }
      />
      <Card2
      
        img={img10}
        title={"SAFETY MANAGEMENT"}
        desc={
          "Safety officer can raise red flag and instruct through photo markers and notes by viewing all the floors from the desktop, which consumes much less time than actual site visits. Prepare workers for what the site will look like when they get there, where the emergency exits are, and which locations not to enter."
        }
      />
      <Card2
      
        img={img11}
        title={"QUICK DOCUMENTATION"}
        desc={
          "Send invoices and payments faster through better schedule reporting. Invite your finance team to your project to be able to prove that work has been done to release the payment sooner and by sending PDF Reports of progress Avoid long discussions to prove that you did the job and accelerate existing operational processes"
        }
      />
    </div>
  );
};

export default ContractorsSection2;
