import React, { useState } from "react";
import logo from "../../assets/NewImages/Top_icon_fixed.png";
import "./navbar.css";
import MenuIcon from "@mui/icons-material/Menu";
import { Link, useNavigate } from "react-router-dom";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";

const Navbar = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  const redirectToLinkedIn = () => {
    window.open("https://www.linkedin.com/company/siteviewai/about/", "_blank");
};
const redirectToFacebook = () => {
  window.open("https://www.facebook.com/profile.php?id=100095546374275", "_blank");
};
  const redirectToYoutube = () => {
    window.open("https://www.youtube.com/channel/UCCtOohV3-rtEacRQgvuFOpQ", "_blank");
};


  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  const handleClickHome = () => {
    navigate("/");
  };
  const handleClick2 = () => {
    navigate("/contact");
  };

  return (
    <div className="navbar">
      <div className="navbarLeftDiv">
        <button className="navButton" onClick={toggleMenu}>
          <MenuIcon />
        </button>
      </div>
      <div>
        <div className="navbarCenterDiv">
          <img
            style={{ cursor: "pointer" }}
            onClick={handleClickHome}
            className="logo"
            src={logo}
            alt="Logo"
          />
        </div>
      </div>
      <div className="navbarRightDiv">
        <div className="navbar-right">
          <p>+91-9819242828</p>
          <button style={{ cursor: "pointer" }} onClick={handleClick2}>
            Get in Touch
          </button>
        </div>
      </div>

      <div className={`fullscreen-overlay ${isOpen ? "open" : ""}`}>
        <button className="close-button" onClick={closeMenu}>
          X
        </button>
        <div className="overlayHeading">
          <h1>Menu</h1>
          <div className="MenuNavSection">
            <div>
              <h4 style={{ fontSize: "2rem" }}>Main Section</h4>
              <div className="TopLine"></div>
              <Link
                style={{
                  textDecoration: "none",
                  color: "black ",
                  fontSize: "30px",
                  cursor:"pointer"
                }}
                to="/"
              >
                <p>Home</p>
              </Link>
              <Link
                to="/howWorks"
                style={{
                  textDecoration: "none",
                  color: "black ",
                  fontSize: "30px",
                  cursor:"pointer"
                }}
              >
                <p>Learn More</p>
              </Link>
              <Link
                to="/aboutUs"
                style={{
                  textDecoration: "none",
                  color: "black ",
                  fontSize: "30px",
                  cursor:"pointer"
                }}
              >
                <p>About Us</p>
              </Link>
            </div>
            <div>
              {" "}
              <h4 style={{ fontSize: "2rem" }}>BENEFITS</h4>
              <div className="TopLine"></div>
              <Link
                style={{
                  textDecoration: "none",
                  color: "black ",
                  fontSize: "30px",
                  cursor:"pointer"
                }}
                to="/owner"
              >
                <p>For Owners</p>
              </Link>
              <Link
                style={{
                  textDecoration: "none",
                  color: "black ",
                  fontSize: "30px",
                  cursor:"pointer"
                }}
                to="/consultant"
              >
                <p>For Consultants & Designers</p>
              </Link>
              <Link
                style={{
                  textDecoration: "none",
                  color: "black ",
                  fontSize: "30px",
                  cursor:"pointer"
                }}
                to="/contractors"
              >
                <p>For Contractors</p>
              </Link>
            </div>
            <div>
              {" "}
              <h4 style={{ fontSize: "2rem" }}>Contact Us</h4>
              <div className="TopLine"></div>
              <Link
                style={{
                  textDecoration: "none",
                  color: "black ",
                  fontSize: "30px",
                  cursor:"pointer"
                }}
                to="/contact"
              >
                <p>Get in Touch</p>
              </Link>
              <FacebookOutlinedIcon onClick={redirectToFacebook}
                className="NavbarIcons" 
                style={{ fontSize: "2rem", color:"black"  }}
              />
              <LinkedInIcon onClick={redirectToLinkedIn}
                className="NavbarIcons"
                style={{ fontSize: "2rem" , color:"black" }}
              />
              <InstagramIcon
                className="NavbarIcons"
                style={{ fontSize: "2rem", color:"black"  }}
              />
              <YouTubeIcon onClick={redirectToYoutube}
                className="NavbarIcons"
                style={{ fontSize: "2rem", color:"black"  }}
              />
            </div>
          </div>
        </div>
        <div></div>
      </div>
    </div>
  );
};

export default Navbar;
