import React from "react";

const HowSection3Div = ({ image, heading, desc, flipImage, flexDirection }) => {
  return (
    <div
      className={`howSection3Div ${flipImage ? "flipImage" : ""}`}
      style={{ flexDirection: flexDirection }}
    >
      <div className={`howsection3Img ${flipImage ? "flipOrder" : ""}`}>
        <div className="" data-aos="fade-up">
          <img className="howIMG" src={image} alt="" />
        </div>
      </div>
      <div className={`howsection3Description ${flipImage ? "flipOrder" : ""}`}>
        <div>
          <h1 className="howsection3Heading">{heading}</h1>
          <span className="howsection3desc">{desc}</span>
        </div>
      </div>
    </div>
  );
};

export default HowSection3Div;
