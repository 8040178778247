import React from "react";
import "./howWork2.css";
import img from "../../../assets/NewImages/Learn More/How it works/CAPTURE-2.jpg";
import img2 from "../../../assets/NewImages/Learn More/How it works/MONITOR-1.jpg";
import img3 from "../../../assets/NewImages/Learn More/How it works/COLLABORATE-2.jpg";
import img4 from "../../../assets/NewImages/Learn More/How it works/COLLABORATE-1.jpg";
import img5 from "../../../assets/NewImages/Learn More/How it works/CAPTURE-1.webp";
import img6 from "../../../assets/NewImages/Learn More/How it works/MONITOR-2.webp";
import img7 from "../../../assets/NewImages/Learn More/How it works/VIEW-1.jpg";
import img8 from "../../../assets/NewImages/Learn More/How it works/VIEW-2.jpg";

const HowSection2 = () => {
  return (
    <div className="HowSection2">
      <div className="howSection2Heading" data-aos="fade-up">
        <h1>
          SiteView is a A.I. and Computer Vision based platform which scans your
          entire project site, so you can manage the projects remotely. See and
          ‘walk’ your project from any part of the world!Save cost and time from
          unnecessary travel, hiring staff and unwanted disputes . For
          developers, contractors, designers and architects.
        </h1>
      </div>
      <div className="howSection2gridImgNdPara">
        <h1>HOW IT WORKS</h1>

        <div className="howDesc_Img" key="">
          <div className="howImage" data-aos="fade-up">
            <h1 className="howImageGrid">1</h1>
            <img className="howImageGrid howImg" src={img} alt="" />
            <img className="howImageGrid" src={img5} alt="" />
            <div className="howImageGrid"></div>
          </div>
          <div className="howDescription">
            <div className="howDescriptionContainer">
              <h3 className="spanStyle">Capture </h3>
              <span className="spanStyle2">
                Simply connect the 360-camera to Ant-Eye’s mobile app, strap
                over the helmet, and go! Effortlessly record 100% of the areas
                you see during site walk and easily combine it with routine site
                visits. Ant-Eye will capture all the surroundings and accurately
                locate them in your drawings. No blind spots, no missed issues.
              </span>
            </div>
          </div>
        </div>
        <div className="howDesc_Img" key="">
          <div className="howDescription">
            <div>
              <span className="spanStyle">VIEW </span>
              <span className="spanStyle2">
                360 photo captures are automatically organized by floor plan and
                date/time. See your site in 360° from anywhere, anytime. Walk
                the site by just using the mouse, as if you are there on-ite.
                Never be dependent on the site team for useful information
                again. Photos are the first source of any records and Ant-Eye
                makes them available instantly.
              </span>
            </div>
          </div>
          <div className="howImage" data-aos="fade-up">
            <h1 className="howImageGrid">2</h1>
            <img className="howImageGrid howImg" src={img7} alt="" />
            <img className="howImageGrid" src={img8} alt="" />
            <div className="howImageGrid"></div>
          </div>
        </div>
        <div className="howDesc_Img" key="">
          <div className="howImage" data-aos="fade-up">
            <h1 className="howImageGrid">3</h1>
            <img className="howImageGrid howImg" src={img6} alt="" />
            <img className="howImageGrid" src={img2} alt="" />
            <div className="howImageGrid"></div>
          </div>
          <div className="howDescription">
            <div>
              <span className="spanStyle">MONITOR </span>
              <span className="spanStyle2">
                Be assured that your project is fully documented and recorded
                for photographic proof of progress—whenever and wherever you
                need It. The whole project is in your pocket. Review historical
                work conditions, verify progress - side by side of different
                dates for every inch of your project site.
              </span>
            </div>
          </div>
        </div>
        <div className="howDesc_Img" key="">
          <div className="howDescription">
            <div>
              <span className="spanStyle">COLABORATE </span>
              <span className="spanStyle2">
                Run site meetings from your office with multiple stakeholders,
                without visiting site ! Showcase the whole project during site
                meetings. Streamline technical discussions, comments,
                instructions, annotate and tag photos, keeping all teams in
                sync, improving transparency and projects moving forward.
              </span>
            </div>
          </div>
          <div className="howImage" data-aos="fade-up">
            <h1 className="howImageGrid">4</h1>
            <img className="howImageGrid howImg" src={img3} alt="" />
            <img className="howImageGrid" src={img4} alt="" />
            <div className="howImageGrid"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowSection2;
