import React, { useEffect } from "react";
import Navbar from "../../Navbar/Navbar";
import ownerMainImage from "../../../assets/NewImages/Benefits/For consultants/for consultant.jpeg.jpg";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useAnimation } from "framer-motion";
import "./consultantSection1.css"
const ConsultantSection1 = () => {
  const { ref, inView } = useInView();
  const animation = useAnimation();
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);
  useEffect(() => {
    if (inView) {
      animation.start({
        scale: 1.1,
        transition: {
          type: "easeInOut",
          duration: 1,
        },
      });
    }
    if (!inView) {
      animation.start({
        scale: 1.3,
        transition: {
          type: "easeOutIn",
          duration: 1,
        },
      });
    }
  }, [inView,animation]);
  return (
    <div className="OwnerSection1">
      <Navbar />
      <div
        ref={ref}
        style={{ position: "relative", width: "100vw", height: "100vh" }}
      >
        <motion.img
          className="ownerHomeImage"
          animate={animation}
          src={ownerMainImage}
          alt="How SiteView Works"
        ></motion.img>
        <div className="centered-Contractors-heading">
          <h1>SITEVIEW FOR</h1>
          <h1>CONSULTANTS</h1>
        </div>
      </div>
    </div>
  );
};

export default ConsultantSection1;
