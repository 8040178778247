import React from "react";
import "./section5.css";
import ImgGif from "../../../assets/NewImages/HOME_PAGE/beforeVafter-min (1).gif";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useAnimation } from "framer-motion";
import { useEffect } from "react";


const Section5 = () => {

    const { ref, inView } = useInView();
  const animation = useAnimation();

  useEffect(() => {
    if (inView) {
      animation.start({
        scale: 1.5,
        transition: {
          type: "easeInOut",
          duration: 1.2,
        },
      });
    }
    if (!inView) {
      animation.start({
        scale: 1,
        transition: {
          type: "easeOutIn",
          duration: 1,
        },
      });
    }
  }, [inView,animation]);

  return (
    <>
      <div className="Section5">
        <div>
          <div className="section5Heading">
            <h1>
              SiteView is an essential tool to monitor interior of construction
              projects.
            </h1>
            <h1>
              From Mega Projects to Villas, From Multistorey buildings to your
              own Home and Office interiors
            </h1>
          </div>
        </div>
        <div ref={ref} className="gifImage">
          <img className="imgGif" src={ImgGif} alt=""></img>
          <motion.h1 animate={animation} className="gifName">GO BACK IN TIME</motion.h1>
        </div>
      </div>
    </>
  );
};

export default Section5;
