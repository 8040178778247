import React from "react";
import Home from "./pages/Home/Home";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Contacts from "./pages/Contacts/Contacts";
import HowWorks from "./pages/HowWorks/HowWorks";
import Owner from "./pages/Owner/Owner";
import Contractors from "./pages/Contractors/Contractors";
import Consultant from "./pages/Consultant/Consultant";
import Blog from "./pages/Blog/Blog";
import Blog2 from "./pages/Blog/Blog2/Blog2";
import Blog3 from "./pages/Blog/Blog3/Blog3";
import Blog4 from "./pages/Blog/Blog4/Blog4";
import "./global.css"
import AboutUs from "./pages/AboutUs/AboutUs";
import Blog5 from "./pages/Blog/Blog5/Blog5";
const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/contact",
    element: <Contacts />,
  },
  {
    path: "/howWorks",
    element: <HowWorks />,
    exact: true,
  },
  {
    path: "/owner",
    element: <Owner />,
  },
  {
    path: "/contractors",
    element: <Contractors />,
  },
  {
    path: "/consultant",
    element: <Consultant />,
  },
  {
    path: "/blog",
    element: <Blog />,
  },
  {
    path: "/blog2",
    element: <Blog2 />,
  },
  {
    path: "/blog3",
    element: <Blog3 />,
  },
  {
    path: "/blog4",
    element: <Blog4 />,
  },
  {
    path: "/blog5",
    element: <Blog5 />,
  },
  {
    path: "/aboutUs",
    element: <AboutUs />,
  },
]);
const App = () => {
  return (
    <div className="app">
      <div className="container">
        <RouterProvider router={router} />
      </div>
    </div>
  );
};

export default App;
