import React from "react";
import img1 from "../../../assets/NewImages/Benefits/For consultants/INCREASE PRODUCTIVITY.jpg";
import img2 from "../../../assets/NewImages/Benefits/For consultants/REDUCE TRAVEL.jpg";
import img3 from "../../../assets/NewImages/Benefits/For consultants/NO MISUNDERSTANDINGS.jpg";
import img4 from "../../../assets/image/EASY INSTRUCTIONS-1.jpg";
import img5 from "../../../assets/NewImages/Benefits/For owners/historical records.jpg";
import img6 from "../../../assets/NewImages/Benefits/For consultants/DESIGN VISUALIZATION.jpg";
import img7 from "../../../assets/NewImages/Benefits/For consultants/BEST PARTNER.jpg";
import img8 from "../../../assets/NewImages/Benefits/For consultants/multiple project.jpg";
import img9 from "../../../assets/NewImages/Benefits/For consultants/WIN ORDERS.jpg";
import img10 from "../../../assets/NewImages/Benefits/For contractors/QUICK DOCUMENTATION.jpg";
import Card2 from "../../OwnerSection/Card2/Card2";

const ConsultantSection2 = () => {
  
  return (
    <div className="ContractorsSection2" style={{overflow:"hidden"}}>
      <Card2
      
        img={img1}
        title={"INCREASE PRODUCTIVITY"}
        desc={
          "Allow team members to work across projects and report on issues or approval processes. Save executive travel time and cost through remote site access for your leadership team."
        }
      />
      <Card2
      
        img={img2}
        title={"REDUCE TRAVEL"}
        desc={
          "No more travelling to project sites for that issue which can be resolved virtually"
        }
      />
      <Card2
      
        img={img3}
        title={"NO MISUNDERTANDINGS"}
        desc={
          "Keep everyone on the same page by clearly communicating the work plan, what has happened and future path for construction. You don’t need to reply on the biased data given by site team."
        }
      />
      <Card2
      
        img={img4}
        title={"EASY INSTRUCTIONS"}
        desc={
          "Know what happens on-site instantly and give instructions to your team at a click :- Coordinate design intent effectively. Enable your engineers to communicate design intent clearly and visually to your design team. Replace the process of scribbling hard-to-read notes on outdated documents into collaborative with easy-to-understand markups on 360° photos."
        }
      />
      <Card2
      
        img={img5}
        title={"LESS REWORKS"}
        desc={
          "No more waiting for that physical site visit for taking timely decisions and check deviations and defects before they become an unavoidable rectification."
        }
      />
      <Card2
      
        img={img6}
        title={"DESIGN VISUALIZATION"}
        desc={
          "Coordinate design intent effectively and Enable your engineers to clearly and visually communicate design intent to your design team. Replace the process of scribbling hard-to-read notes on outdated documents into collaborative with easy to understand markups on 360° photos."
        }
      />
      <Card2
      
        img={img7}
        title={"BEST PARTNER"}
        desc={
          "Providing work records, as built drawings and handover packages that really provide value to the owner in the long term differentiates your services from the crowd and Be The Best Partner to your client."
        }
      />
      <Card2
      
        img={img8}
        title={"MULTIPLE PROJECTS"}
        desc={
          "As consultant’s, you need to manage and switch between multiple projects in your portfolio in an instant. You rely on photographs from site team which are not enough to take decisions. Reduce the dependency and take decisions though your own visits at any time of the day. Access and overlook progress and visit any of your project at any time and at any place in the world. We understand the value of your time."
        }
      />
      <Card2
      
        img={img9}
        title={"WIN ORDERS"}
        desc={
          "Improve your bid presentations and by providing work records, as built drawings and handover packages that really provide value to the owner in the long term differentiates your services from the crowd ."
        }
      />
      <Card2
      
        img={img10}
        title={"QUICK DOCUMENTATION"}
        desc={
          "Send invoices and payments faster through better schedule reporting. Invite your finance team to your project to be able to prove that work has been done to release the payment sooner and by sending PDF Reports of progress Avoid long discussions to prove that you did the job and accelerate existing operational processes"
        }
      />
      
    </div>
  );
};

export default ConsultantSection2;
