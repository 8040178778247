import React from "react";
import Card2 from "../Card2/Card2";
import "./ownerSection2.css";
import img1 from "../../../assets/NewImages/Benefits/For owners/no hearsay.jpg";
import img2 from "../../../assets/NewImages/Benefits/For owners/instant instruction.jpg";
import img3 from "../../../assets/NewImages/Benefits/For owners/reduce cost.jpg";
import img4 from "../../../assets/NewImages/Benefits/For owners/save time.jpg";
import img5 from "../../../assets/NewImages/Benefits/For owners/historical records.jpg";
import img6 from "../../../assets/NewImages/Benefits/For owners/multiple project.jpg";
import img7 from "../../../assets/NewImages/Benefits/For owners/property management.jpg";
import img8 from "../../../assets/NewImages/Benefits/For owners/virtual sales.jpg";
const OwnerSection2 = () => {
  return (
    <div className="OwnerSection2">
      <Card2
        img={img1}
        title={"No Heresay"}
        desc={
          "Transparency is increased into your jobsites by having photos of all your projects that are organized by location and time on a single platform."
        }
      />
      <Card2
        img={img2}
        title={"Instant Instructions"}
        desc={
          "Know what happens on-site instantly and give instructions to your team at a click.."
        }
      />
      <Card2
        img={img3}
        title={"Reduce Cost"}
        desc={
          "Save on reworks, litigations and hiring multiple staff to monitor your projects and also frequent travel to sites."
        }
      />
      <Card2
        img={img4}
        title={"Save Time"}
        desc={
          "As owners we understand the importance of your time and that of your team. Hours are saved just to see the progress of the work. SiteView enables you to see your multiple project sites from your desktop without actually visiting the site."
        }
      />
      <Card2
        img={img5}
        title={"Historical Record"}
        desc={
          "SiteView provides a complete progress record of your project history that can be used for future renovations, evidence of work, and valuable tool at time of litigations."
        }
      />
      <Card2
        img={img6}
        title={"Multiple Project"}
        desc={
          "SiteView is a Common platform where all your projects can be viewed and managed at once."
        }
      />
      <Card2
        img={img7}
        title={"Property Management"}
        desc={
          "SiteView is useful during operation and maintenance of property . Now you have a true as-built to maintain and operate the property and its facilities."
        }
      />
      <Card2
        img={img8}
        title={"Virtual Sales"}
        desc={
          "Whether you have projects spread across the globe or just one project across town, physically visiting your site is time consuming. Save your time by showing investors and tenants the site online–without requiring an in-person visit."
        }
      />
    </div>
  );
};

export default OwnerSection2;
