import React, { useEffect } from "react";
import "./section7.css";
import Card from "../../Card/Card";
import imgblog1 from "../../../assets/NewImages/Blog images/Blog1_Virtual Construction Management Platform  - IMAGE.png";
import imgblog2 from "../../../assets/NewImages/Blog images/BLOG7_MIXED REALITY IN CONSTRUCTION- IMAGE.jpg";
import imgblog3 from "../../../assets/NewImages/Blog images/blog4_Virtual Management - IMAGE.jpg";
import imgblog4 from "../../../assets/NewImages/Blog images/blog5_Women Of Steel in construction- IMAGE.png";
import imgblog5 from "../../../assets/NewImages/Blog images/BLOG6_ WHAT IS mixed reality- IMAGE.jpg";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useAnimation } from "framer-motion";

const Section7 = () => {
  const { ref, inView } = useInView();
  const animation = useAnimation();

  useEffect(() => {
    if (inView) {
      animation.start({
        y: -20,
        transition: {
          type: "easeInOut",
          duration: 1,
        },
      });
    }
    if (!inView) {
      animation.start({
        y: 10,
        transition: {
          type: "easeOutIn",
          duration: 1,
        },
      });
    }
  }, [inView, animation]);

  return (
    <div className="Section7">
      <div className="section7Heading">
        <h1 data-aos="fade-up" data-aos-duration="1000">
          TRENDS
        </h1>
      </div>
      <div>
        <div ref={ref} className="blogDivs">
          <motion.div animate={animation} className="card1div">
            <Link to="/blog" style={{ textDecoration: "none", color: "black" }}>
              <Card
                image={imgblog1}
                title={"Virtual Construction Mangagement Platform "}
                description={"@SiteView"}
              />
            </Link>
          </motion.div>
          <div className="card2div">
            <Link
              to="/blog3"
              style={{ textDecoration: "none", color: "black" }}
            >
              <Card
                image={imgblog2}
                title={
                  "Mixed Reality in Construction Industry: Uses, Advantages and Limitations"
                }
                description={"@SiteView"}
              />
            </Link>
          </div>
          <div className="card3div">
            <Link
              to="/blog4"
              style={{ textDecoration: "none", color: "black" }}
            >
              <Card
                image={imgblog3}
                title={"Challenges with Construction"}
                description={"@SiteView"}
              />
            </Link>
          </div>
          <div className="card4div">
            <Link
              to="/blog2"
              style={{ textDecoration: "none", color: "black" }}
            >
              <Card
                image={imgblog4}
                title={"Women Of Steel- Celebrating The Women In Construction"}
                description={"@SiteView"}
              />
            </Link>
          </div>
          <div className="card5div">
            <Link to="/blog5"    style={{ textDecoration: "none", color: "black" }}>
              <Card
                image={imgblog5}
                title={
                  "Virtual Management & Virtual Teams: A COVID-19 Blessing For Construction"
                }
                description={"@SiteView"}
              />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

//*animations
export default Section7;
