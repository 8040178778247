import React, { useEffect } from "react";
import "./howSection1.css";
import Navbar from "../../Navbar/Navbar";
import howWorksMainImage from "../../../assets/image/how-ito.jpg";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useAnimation } from "framer-motion";

const HowSection1 = () => {
  const { ref, inView } = useInView();
  const animation = useAnimation();

  useEffect(() => {
    if (inView) {
      animation.start({
        scale: 1.1,
        transition: {
          type: "easeInOut",
          duration: 1,
        },
      });
    }
    if (!inView) {
      animation.start({
        scale: 1.3,
        transition: {
          type: "easeOutIn",
          duration: 1,
        },
      });
    }
  }, [inView, animation]);

  return (
    <>
      <Navbar />
      <div className="HowSection1">
        <div ref={ref} style={{ position: "relative" }}>
          <motion.img
            animate={animation}
            src={howWorksMainImage}
            alt="How Anteye Works"
          ></motion.img>
          <div className="centered-heading">
            <h1 data-aos="fade-up" data-aos-duration="1000">
              HOW <br /> SiteView <br /> Works
            </h1>
          </div>
        </div>
      </div>
    </>
  );
};

export default HowSection1;
