import React, { useEffect, useState } from "react";
import "./howSection3.css";
import img1 from "../../../assets/NewImages/Learn More/how it work 2/NO_BLINDSPOT-min.jpg";
import img2 from "../../../assets/NewImages/Learn More/how it work 2/historical_records-min.jpg";
import img3 from "../../../assets/NewImages/Learn More/how it work 2/CONCEALED_WORK-min.jpg";
import img4 from "../../../assets/NewImages/Learn More/how it work 2/VIRTUAL_PHOTOS-min.jpg";
import img5 from "../../../assets/NewImages/Learn More/how it work 2/MAKE_NOTES-min.jpg";
import HowSection3Div from "./HowSection3Div";

const HowSection3 = () => {
  const [isMobileView, setIsMobileView] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 767);
    };

    // Initial check on component mount
    handleResize();

    // Event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const howData = [
    {
      image: img1,
      heading: "NO BLINDSPOT",
      desc:
        "Walk any floor and any room you want with just a couple of clicks. " +
        "Since it’s securely stored in the cloud, you’ll be able to access " +
        "your whole project from any device, anywhere you have an internet " +
        "connection.",
    },
    {
      image: img2,
      heading: "TIME TRAVEL",
      desc:
        "Travel your site back in time. SiteView records the visuals from Day-1 and stores it for years even after completion of the project. Select the date of your walk, as if you are there on that day. This forms an important tool to avoid disputes and serve as an evidence during litigations",
    },
    {
      image: img3,
      heading: "COMPARE PROGRESS",
      desc:
        "Compare views of any two dates on your screen. Compare progress between any two dates, make as-built drawings, nothing is hidden behind that plaster.",
    },
    {
      image: img4,
      heading: "VIRTUAL PHOTOS",
      desc:
        "Take photos and screenshots during your virtual walk of any floor directly from the screen. Send the photo to your teams or save on your computer directly with a click. Never be without that important photo you need.",
    },
    {
      image: img5,
      heading: "MAKE NOTES",
      desc:
        "Directly from your screen you can now make notes, give instruction, mark issues, and send messages for your team to quickly resolve issues in seconds. Also, a useful tool to have Virtual Site Meetings from convenience of your office.",
    },
  ];

  return (
    <>
      {howData.map((item, index) => (
        <div className="HowSection3" key={index}>
          <HowSection3Div
            image={item.image}
            heading={item.heading}
            desc={item.desc}
            flipImage={index % 2 === 1}
            flexDirection={isMobileView ? "column" : index % 2 === 1 ? "row-reverse" : "row"}
          />
        </div>
      ))}
    </>
  );
};

export default HowSection3;
