import React, { useEffect, useState } from "react";
import "./homeimage.css";
import homeImageWallpaper from "../../../assets/NewImages/home_IMAGE.jpg";
import HomeImg2 from "../../../assets/NewImages/anteye website.gif";
import HomeImg3 from "../../../assets/NewImages/yesee (2).gif";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useAnimation } from "framer-motion";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { Link } from "react-router-dom"; 
import SweetAlert from 'react-bootstrap-sweetalert'; 
import { CopyToClipboard } from "react-copy-to-clipboard";
 


const HomeImage = () => {
  
  const [showAlert, setShowAlert] = useState(false);
  const [copied, setCopied] = useState(false);

  const showSuccessAlert = () => {
    setShowAlert(true);
    // Hide the alert after 5 seconds
    setTimeout(() => {
      setShowAlert(false);
      setCopied(false);
    }, 5000);
  };

  const handleAlertConfirm = () => {
    setShowAlert(false);
    setCopied(false);
  };
  const handleClick = () => {
    if (!document.getElementById("email").value) {
      // The email input is empty, do not proceed
      return;
    }
  
    showSuccessAlert();
  };
  

  const handleCopy = () => {
    setCopied(true);
  };

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const { ref, inView } = useInView();
  const animation = useAnimation();
  useEffect(() => {
    if (inView) {
      animation.start({
         scale: 1.1,
        transition: {
          type: "easeInOut",
          duration: 1,
        },
      });
    }
    if (!inView) {
      animation.start({
        scale: 1,
        transition: {
          type: "easeOutIn",
          duration: 1,
        },
      });
    }
  }, [inView, animation]);

  return (
    <>
      <div className="homeImagediv">
        <div className="homeImage" data-aos="zoom-in" data-aos-duration="1000">
          <img className="homeimg" src={homeImageWallpaper} alt="" />

          <div className="buttonContainer">
            <Link to="/howworks">
              <button className="homeImageBtn1">Learn More</button>
            </Link>
            <button className="homeImageBtn_2" onClick={handleClickOpen}>
              Watch Video
            </button>
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogContent>
                <div className="videoWrapper">
                  <iframe
                    className="youtubeVideo"
                    src="https://www.youtube.com/embed/eVKXEJMt1Vs"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                  ></iframe>
                </div>
              </DialogContent>
            </Dialog>
          </div>
        
        
     
         
      <div className="containerDiv" style={{textAlign:"center"}}>

      <div className="form-container"  >
                <p style={{fontSize:"24px",marginTop:"5px"}}>Please leave your mail here for early access</p>             
<form className="from-b" action="https://formspree.io/f/xoqoeqwo" method="POST"> 
          <input
        type="email"
        id="email"
        name="email"
        required
        className="input-field"
        style={{fontWeight:"bold",borderImage:"linear-gradient(90deg, var(--primaryColorGradiant), var(--secondaryColorGradiant)) 1"}}
        placeholder="Enter your mail"
      />

     

      <button style={{marginLeft:"17px"}} className="submit-button"
    
      onClick={handleClick}>
        Submit
      </button>
              </form>
 
      <SweetAlert
        show={showAlert}
        success
        title="Thank you for showing interest!"
        onConfirm={handleAlertConfirm}
        confirmBtnCssClass="alert-btn"
      >
        {copied ? (
          <div>
            <h1>
              Thank you for showing interest. We will get back to you shortly.
              In the meanwhile, share SiteView with your team.
            </h1>
            <p>Link copied to clipboard!</p>
          </div>
        ) : (
          <div>
            <p>
              Thank you for showing interest. We will get back to you shortly.
              In the meanwhile, share SiteView with your team.
            </p>
            <CopyToClipboard
              text="https://siteview.ai/" // Replace with your website link
              onCopy={handleCopy}
            >
              <button className="copy-link-button">Copy Link</button>
            </CopyToClipboard>
          </div>
        )}
      </SweetAlert>
    </div>
   </div>
      </div>


        <div  ref={ref} className="homeimg2Div" style={{paddingTop:"20px"}}>
 
      

          <motion.img
            animate={animation}
            className="homeImg2"
            src={HomeImg2}
            alt="Home Image 2"
          ></motion.img>
          <motion.img
            animate={animation}
            className="homeImg2"
            src={HomeImg3}
            alt="Home Image 2"
          ></motion.img>

 
        </div>
        
      </div>
    </>
  );
};

export default HomeImage;
