import React, { useState } from "react";
import IFrame from "react-iframe";
import "./section4.css";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { useNavigate } from "react-router-dom";
const Section4 = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleLearnMore = () => {
    navigate("/howworks");
  };
  return (
    <div className="section4">
      <div className="section4Heading">
        <h1>Essential tool for Construction Management</h1>
        <button className="section4button_1" onClick={handleLearnMore}>
          Learn More
        </button>
        <button className="section4button_2" onClick={handleClickOpen}>
          Watch Video
        </button>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
          <div className="videoWrapper">
                  <iframe
                    className="youtubeVideo"
                    src=""
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                  ></iframe>
                </div>
          </DialogContent>
        </Dialog>
      </div>
      <div className="section4Img">
        <IFrame
          url="https://momento360.com/e/u/efd1b70b5edf4f9a844e2c33060da52c?utm_campaign=embed&utm_source=other&heading=0&pitch=0&field-of-view=75&size=medium&display-plan=true"
          width="80%"
          height="700px"
          allowFullScreen={true}
          allow="accelerometer; magnetometer; gyroscope"
          style={{
            display: "block",
            margin: "20px auto",
            border: "none",
            borderRadius: "8px",
            boxShadow:
              "0 1px 1px rgba(0,0,0,0.11),0 2px 2px rgba(0,0,0,0.11),0 4px 4px rgba(0,0,0,0.11),0 6px 8px rgba(0,0,0,0.11),0 8px 16px rgba(0,0,0,0.11)",
          }}
          className="img-360"
        />
      </div>
    </div>
  );
};

export default Section4;
