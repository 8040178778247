import React from 'react'
import ContractorsSection1 from '../../components/ContractorsSections/ContractorsSection1/ContractorsSection1'
import ContractorsSection2 from '../../components/ContractorsSections/ContractorsSection2/ContractorsSection2'
import "./contractors.css"
import Footer from '../../components/Footer/Footer'
const Contractors = () => {
  return (
    <div className='Contractors'>
    <ContractorsSection1 />
    <ContractorsSection2 />
    <Footer />
    </div>
  )
}

export default Contractors