import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";

const Card2 = ({ img, title, desc }) => {
  return (
    <Card sx={{ maxWidth: 400, margin: "10px" }} data-aos="fade-up">
      <CardMedia
        sx={{
          height: 400,
          "@media (max-width: 600px)": {
            height: 200, // Adjust the height for mobile view
          },
        }}
        image={img}
        title="green iguana"
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {title}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {desc}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default Card2;
